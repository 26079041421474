import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"
import ProductContainer from "../components/productContainer"
import descriptionData from "../content/description.json"
import buildSchema from "../script/buildschema"
import { Helmet } from "react-helmet"


const IndexPage = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  // const data = props.data.allAirtable.edges
  const {
    ref,
    inView: fixHeader,
    entry,
  } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  buildSchema(edges)


  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.shopformaldress.com/new-dresses"
        />
        <title>{`New Prom Dresses | Shop Formal Dress`}</title>
        <meta name="description" content={descriptionData.all.pageDescription}/>        
      </Helmet>

      <div className="container mt-6 px-12 mx-auto max-w-screen-xl">
                                                  <p className="text-sm">Find your perfect formal dress with our new <Link to="/search" className="font-bold">search page </Link></p>
                                    </div>
      
      

      <div className="product-container grid grid-cols-2 onf md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} path="new" dressType="New" />
      </div>

      <h1 className="text-2xl mt-5 mb-5 font-bold">
          {descriptionData.all.heading}
        </h1>

        <p className="">
          We curate the most stylish and sophisticated{" "}
          <Link to={"/formal-dresses"}>Formal dresses</Link> from various sources, 
          eliminating the need for you to browse multiple stores and boutiques. 
          Our sizable and high-quality collection of formal / prom dresses is 
          updated weekly with fresh styles, meaning there's always something new to discover. 
          Our offerings cater to everyone style.
          So step into the spotlight with confidence and style at your next formal event by 
          browsing in Shop Formal Dress.{" "}          
        </p>

        <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">
 
        <p className="text-l mt-5 mb-5 font-bold">
          Shop Formal Dress introduces a variety of new dress styles every Friday. This provides a fresh selection of fashionable items for customers to browse through. Be sure to check in each week for unique updates to your wardrobe.
        </p>
      </div>

    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "dresses_all" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
            product_images_2nd
            product_images_3rd
            product_images_4th
            product_images_5th
            product_images_6th
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={descriptionData.all.pageTitle}
    description={descriptionData.all.pageDescription}
  />
)

export default IndexPage
